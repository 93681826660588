body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  min-height: 1200px;
}

#footer {
  cursor: default;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .service-preview {
    padding-bottom: 75px;
  }

  .service-preview:last-child {
    padding-bottom: 0;
  }
}

.service-preview > .read-more {
  visibility: hidden;
}


@media (max-width: 980px) {
  #page > #content {
    padding: 0 15px 0 15px;
  }

  #content > div.aui-page-panel-inner {
    width: 100%;
  }
}

.atlas-article {
  border: 1px solid black;
  border-radius: 6px;
  display: block;
  margin: 10px 0;
  overflow: auto;
  margin-bottom: 20px;
}

.atlas-article > .section:first-of-type {
  border: none;
}

.atlas-article > .section {
  border-top: 1px solid black;
  padding: 15px;
  margin: 0;
}

.main-body {
  padding-left: 15px;
  padding-right: 15px;
  border-spacing: 0;
  display: -webkit-flex;
  display: flex;
  table-layout: fixed;
  width: 100%;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.aui-page-panel-inner {
  padding: 0;
  display: table;
  table-layout: fixed;
  width: 100%;
}

