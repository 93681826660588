@media (max-width: 980px) {
  #page > #content {
    padding: 0 15px 0 15px;
  }

  #content > div.aui-page-panel-inner {
    width: 100%;
  }
}

.atlas-article {
  border: 1px solid black;
  border-radius: 6px;
  display: block;
  margin: 10px 0;
  overflow: auto;
  margin-bottom: 20px;
}

.atlas-article > .section:first-of-type {
  border: none;
}

.atlas-article > .section {
  border-top: 1px solid black;
  padding: 15px;
  margin: 0;
}

.main-body {
  padding-left: 15px;
  padding-right: 15px;
  border-spacing: 0;
  display: flex;
  table-layout: fixed;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
}

.aui-page-panel-inner {
  padding: 0;
  display: table;
  table-layout: fixed;
  width: 100%;
}
