@media (max-width: 768px) {
  .service-preview {
    padding-bottom: 75px;
  }

  .service-preview:last-child {
    padding-bottom: 0;
  }
}

.service-preview > .read-more {
  visibility: hidden;
}
