#root {
  min-height: 1200px;
}

#footer {
  cursor: default;
  user-select: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
